import { isDayInArrOfDates, resetTimeOnDate, setDateTimeToLastSecond } from "@smartrr/shared/utils/dateUtils";
import { DatePickerModal } from "@vendor-app/app/_sharedComponents/DatePickerModal";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useDisabledDatesByBillingDays } from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/hooks/useDisabledDatesByBillingDays";
import { useCallback, useMemo } from "react";

import { useSetNextOrderModalStore } from "./useSetNextOrderModalStore";

interface Props {
  initialISODate: string;
  title?: string;
  bodyElement?: JSX.Element;

  onConfirm(date: Date): void;

  allowSendNow: boolean;
}

export function SetNextOrderDateModal({ initialISODate, title, onConfirm, bodyElement, allowSendNow }: Props) {
  const { isModalOpen, closeModal } = useSetNextOrderModalStore();
  const initialDate = useMemo(() => new Date(initialISODate), [initialISODate]);
  const { addToast } = useToast();
  const todaysDateReset = useMemo(() => resetTimeOnDate(new Date()), []);
  const firstEligibleDate = useMemo(() => {
    const date = new Date();
    if (allowSendNow) {
      // firstEligible is set to yesterday opposed to today as disabledDatesBefore includes the provided day as a disabled date
      date.setDate(date.getDate() - 1);
    }
    return date;
  }, [allowSendNow]);

  const omittedDates = useMemo(() => {
    if (allowSendNow) {
      return [firstEligibleDate];
    }

    return [firstEligibleDate, todaysDateReset];
  }, [firstEligibleDate, allowSendNow]);

  const disabledDatesFromBillingDays = useDisabledDatesByBillingDays(initialDate, omittedDates);
  const disabledDatesBefore = useMemo(() => setDateTimeToLastSecond(firstEligibleDate), [firstEligibleDate]);

  const onDateConfirm = useCallback(
    (date: Date) => {
      const isNotToday: boolean = date.getTime() !== todaysDateReset.getTime();

      // We're currently not letting the user choose the dates
      // that are not in shop's billing days except for
      // the current date (reason why isNotToday is there)
      // because of sendNow.
      if (
        (isNotToday && isDayInArrOfDates(disabledDatesFromBillingDays, date)) ||
        date.getTime() < todaysDateReset.getTime()
      ) {
        addToast("Select an available date", true);
        return;
      }
      onConfirm(date);
      closeModal();
    },
    [disabledDatesFromBillingDays, firstEligibleDate]
  );

  return (
    <DatePickerModal
      key={title ?? "set-next-order-modal"}
      title={title ?? "Select the next order date for this subscription"}
      bodyElement={bodyElement}
      initialDate={initialDate}
      open={isModalOpen}
      onConfirm={onDateConfirm}
      onClose={closeModal}
      disabledDatesBefore={disabledDatesBefore}
      helpText={"By selecting this date, you are bypassing your shop's billing days and times."}
    />
  );
}
