import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { DeepExtractType, DeepExtractTypeSkipArrays } from "ts-deep-extract-types";

import { paginatedRequestStreamShopifyGraphQL } from "./_utils";
import {
  GetSubscriptionContract,
  GetSubscriptionContractDiscounts,
  GetSubscriptionContractDiscountsQuery,
  GetSubscriptionContractDiscountsQueryVariables,
  GetSubscriptionContractLineCount,
  GetSubscriptionContractLineCountQuery,
  GetSubscriptionContractLineCountQueryVariables,
  GetSubscriptionContractOrderIds,
  GetSubscriptionContractOrderIdsQuery,
  GetSubscriptionContractOrderIdsQueryVariables,
  GetSubscriptionContractQuery,
  GetSubscriptionContractQueryVariables,
  GetSubscriptionContracts,
  GetSubscriptionContractsForCustomer,
  GetSubscriptionContractsForCustomerQuery,
  GetSubscriptionContractsForCustomerQueryVariables,
  GetSubscriptionContractsIds,
  GetSubscriptionContractsIdsQuery,
  GetSubscriptionContractsIdsQueryVariables,
  GetSubscriptionContractsQuery,
  GetSubscriptionContractsQueryVariables,
  GetSubscriptionDraftShippingOptions,
  GetSubscriptionDraftShippingOptionsQuery,
  GetSubscriptionDraftShippingOptionsQueryVariables,
  GetSubscriptionLineItems,
  GetSubscriptionLineItemsQuery,
  GetSubscriptionLineItemsQueryVariables,
  SubscriptionContractCreate,
  SubscriptionContractCreateInput,
  SubscriptionContractCreateMutation,
  SubscriptionContractCreateMutationVariables,
  SubscriptionContractSetNextBillingDate,
  SubscriptionContractSetNextBillingDateMutation,
  SubscriptionContractSetNextBillingDateMutationVariables,
  SubscriptionContractSubscriptionStatus,
  SubscriptionContractUpdate,
  SubscriptionContractUpdateMutation,
  SubscriptionContractUpdateMutationVariables,
  SubscriptionDraftDiscountAdd,
  SubscriptionDraftDiscountAddMutation,
  SubscriptionDraftDiscountAddMutationVariables,
  SubscriptionDraftDiscountCodeApply,
  SubscriptionDraftDiscountCodeApplyMutation,
  SubscriptionDraftDiscountCodeApplyMutationVariables,
  SubscriptionDraftDiscountRemove,
  SubscriptionDraftDiscountRemoveMutation,
  SubscriptionDraftDiscountRemoveMutationVariables,
  SubscriptionDraftDiscountUpdate,
  SubscriptionDraftDiscountUpdateMutation,
  SubscriptionDraftDiscountUpdateMutationVariables,
  SubscriptionDraftFreeShippingDiscountAdd,
  SubscriptionDraftFreeShippingDiscountAddMutation,
  SubscriptionDraftFreeShippingDiscountAddMutationVariables,
  SubscriptionDraftFreeShippingDiscountUpdate,
  SubscriptionDraftFreeShippingDiscountUpdateMutation,
  SubscriptionDraftFreeShippingDiscountUpdateMutationVariables,
  SubscriptionFreeShippingDiscountInput,
  SubscriptionLineFragmentFragment,
  SubscriptionManualDiscountFragmentFragment,
  SubscriptionManualDiscountInput,
} from "./api";
import { ISODateString } from "../entities/ISODateString";
import { IShopifyData } from "../entities/ShopifyData";
import { AppReqResponse } from "../req";
import { IStreamMeta } from "../utils/paginatedRequestStream";
import { parseShopifyGraphQLResponse } from "../utils/parseShopifyGraphQLResponse";

import { apolloShopifyMutation, apolloShopifyQuery, throws } from ".";

export type SubscriptionContractFromQuery = DeepExtractType<
  GetSubscriptionContractQuery,
  ["subscriptionContract"]
>;
type SubscriptionContractLineItemsFromQuery = DeepExtractType<
  GetSubscriptionLineItemsQuery,
  ["subscriptionContract", "lines"]
>;
export type SubscriptionLineItemFromQuery = DeepExtractTypeSkipArrays<
  SubscriptionContractLineItemsFromQuery,
  ["edges", "node"]
>;
export type SubscriptionContractDiscountFromQuery = DeepExtractTypeSkipArrays<
  GetSubscriptionContractDiscountsQuery,
  ["subscriptionContract", "discounts", "edges", "node"]
>;
export type SubscriptionOrderIdFromQuery = DeepExtractTypeSkipArrays<
  GetSubscriptionContractOrderIdsQuery,
  ["subscriptionContract", "orders", "edges", "node"]
>;

export interface ISubscriptionContractStreamEmission {
  contract: SubscriptionContractFromQuery;
  lines: SubscriptionLineItemFromQuery[];
  discounts: SubscriptionContractDiscountFromQuery[];
  orderIds: string[];
}

export async function streamAllSubscriptionContracts(
  orgId: string,
  shopifyData: IShopifyData,
  onEmit: (collectionsStreamMeta: IStreamMeta, response: ISubscriptionContractStreamEmission) => Promise<any>,
  client: ApolloClient<object>,
  oldestProductDateRes: ISODateString | null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  forceAll = false,
  reverse = false,
  logger?: (message: string) => void
): Promise<AppReqResponse<Date>> {
  const batchStartTime = new Date();

  return paginatedRequestStreamShopifyGraphQL<
    GetSubscriptionContractsQuery,
    DeepExtractTypeSkipArrays<GetSubscriptionContractsQuery, ["subscriptionContracts", "edges", "node"]>
  >(
    subscriptionContractsCursor =>
      queryShopifySubscriptionContracts(
        client,
        subscriptionContractsCursor,
        1,
        reverse
        // undefined,
        // `updated_at:<${oneSecondBeforeOldestContractDate}`
        // forceAll || !oldestProductDateRes ? null : `updated_at:<${oldestProductDateRes}`
      ),
    result => result.data.subscriptionContracts,
    async paginatedSubscriptionContractQueryResult => {
      try {
        if (logger) {
          logger(
            `paginatedSubscriptionContractQueryResult: ${JSON.stringify(
              paginatedSubscriptionContractQueryResult,
              null,
              2
            )}`
          );
        }
        const contractReference = paginatedSubscriptionContractQueryResult.data.subscriptionContracts.edges[0];
        if (
          !contractReference ||
          contractReference.node.status === SubscriptionContractSubscriptionStatus.Failed
        ) {
          return null;
        }

        const { id: contractId, lineCount } = contractReference.node;

        const discountsPromise = getShopifySubscriptionContractDiscounts(contractId, client);
        const linesPromise = getShopifySubscriptionContractLineItems(contractId, lineCount, client);

        const orders: SubscriptionOrderIdFromQuery[] = [];
        const ordersPromise = paginatedRequestStreamShopifyGraphQL(
          orderCursor => queryShopifySubscriptionContractOrderIds(client, contractId, orderCursor),
          result => result.data.subscriptionContract!.orders,
          async paginatedOrderResult => {
            if (paginatedOrderResult.data.subscriptionContract?.orders.edges.length) {
              const nodes = paginatedOrderResult.data.subscriptionContract?.orders.edges.map(({ node }) => node);
              for (const node of nodes) {
                orders.push(node);
              }
            }
          }
        );

        const [subscriptionDiscounts, subscriptionLines] = await Promise.all([
          discountsPromise,
          linesPromise,
          ordersPromise,
        ]);

        if (logger) {
          logger(`Got subscription discounts: ${JSON.stringify(subscriptionDiscounts, null, 2)}`);
          logger(`Got subscription lines: ${JSON.stringify(subscriptionLines, null, 2)}`);
          logger(`Got subscription orders: ${JSON.stringify(orders, null, 2)}`);
        }

        await onEmit(
          { batchStartTime },
          {
            contract: paginatedSubscriptionContractQueryResult.data.subscriptionContracts.edges[0].node,
            discounts: subscriptionDiscounts,
            lines: subscriptionLines,
            orderIds: orders.map(o => o.id),
          }
        );
      } catch (error) {
        if (logger) {
          logger(`Error in contract streaming: ${(error as Error).message}`);
        }
        return Promise.reject(error);
      }
    }
  ).then(res => {
    if (logger) {
      logger(`Finishing up streamAllSubscriptionContracts, res: ${JSON.stringify(res, null, 2)}`);
    }
    switch (res.type) {
      case "error": {
        return res;
      }
      case "success": {
        return {
          type: "success",
          body: batchStartTime,
        };
      }
    }
  });
}

export async function getShopifySubscriptionContractDiscounts(contractId: string, client: ApolloClient<object>) {
  const discounts: SubscriptionManualDiscountFragmentFragment[] = [];
  await paginatedRequestStreamShopifyGraphQL(
    discountCursor => queryShopifySubscriptionContractDiscounts(client, contractId, discountCursor),
    result => result.data.subscriptionContract!.discounts,
    async paginatedResult => {
      if (paginatedResult.data.subscriptionContract?.discounts.edges.length) {
        const nodes = paginatedResult.data.subscriptionContract?.discounts.edges.map(({ node }) => node);
        for (const node of nodes) {
          discounts.push(node);
        }
      }
    }
  );
  return discounts;
}

export async function getShopifySubscriptionContractLineItems(
  contractId: string,
  lineCount: number,
  client: ApolloClient<object>
): Promise<SubscriptionLineFragmentFragment[]> {
  const res = await queryShopifySubscriptionContractLineItems(contractId, lineCount, client);
  const parsed = parseShopifyGraphQLResponse(res, "subscriptionContract");
  return parsed.lines.edges.map(({ node }) => node);
}

export function queryShopifySubscriptionContracts(
  client: ApolloClient<object>,
  cursor: string | null,
  first = 1,
  reverse = false
) {
  return apolloShopifyQuery<GetSubscriptionContractsQuery, GetSubscriptionContractsQueryVariables>(
    {
      query: GetSubscriptionContracts,
      variables: {
        first,
        cursor,
        reverse,
      },
    },
    client
  );
}

export async function getShopifySubscriptionContractsIds(
  client: ApolloClient<object>,
  cursor: string | null,
  first = 1
): Promise<any> {
  const res = await queryShopifySubscriptionContractsIds(client, cursor, first);
  const parsed = parseShopifyGraphQLResponse(res, "subscriptionContracts");
  return parsed.edges;
}

export function queryShopifySubscriptionContractsIds(
  client: ApolloClient<object>,
  cursor: string | null,
  first = 1
) {
  return apolloShopifyQuery<GetSubscriptionContractsIdsQuery, GetSubscriptionContractsIdsQueryVariables>(
    {
      query: GetSubscriptionContractsIds,
      variables: {
        first,
        cursor,
      },
    },
    client
  );
}

export function queryShopifySubscriptionContractOrderIds(
  client: ApolloClient<object>,
  contractId: string,
  orderCursor?: string | null
) {
  return apolloShopifyQuery<GetSubscriptionContractOrderIdsQuery, GetSubscriptionContractOrderIdsQueryVariables>(
    {
      query: GetSubscriptionContractOrderIds,
      variables: {
        id: contractId,
        orderCursor,
      },
    },
    client
  );
}

export function queryShopifySubscriptionContractsForCustomer(
  id: string,
  client: ApolloClient<object>,
  cursor: string | null,
  first = 1
) {
  return apolloShopifyQuery<
    GetSubscriptionContractsForCustomerQuery,
    GetSubscriptionContractsForCustomerQueryVariables
  >(
    {
      query: GetSubscriptionContractsForCustomer,
      variables: {
        id,
        first,
        cursor,
      },
    },
    client
  );
}

export function queryShopifySubscriptionContract(id: string, client: ApolloClient<object>) {
  return apolloShopifyQuery<GetSubscriptionContractQuery, GetSubscriptionContractQueryVariables>(
    {
      query: GetSubscriptionContract,
      variables: {
        id,
      },
    },
    client
  );
}

export function queryShopifySubscriptionContractLineItems(
  id: string,
  lineCount: number,
  client: ApolloClient<object>
) {
  return apolloShopifyQuery<GetSubscriptionLineItemsQuery, GetSubscriptionLineItemsQueryVariables>(
    {
      query: GetSubscriptionLineItems,
      variables: {
        id,
        first: lineCount,
      },
    },
    client
  );
}

export function queryShopifySubscriptionContractLineCount(id: string, client: ApolloClient<object>) {
  return apolloShopifyQuery<
    GetSubscriptionContractLineCountQuery,
    GetSubscriptionContractLineCountQueryVariables
  >(
    {
      query: GetSubscriptionContractLineCount,
      variables: {
        id,
      },
    },
    client
  );
}

export function queryShopifySubscriptionContractDiscounts(
  client: ApolloClient<object>,
  id: string,
  discountCursor?: string | null
) {
  return apolloShopifyQuery<
    GetSubscriptionContractDiscountsQuery,
    GetSubscriptionContractDiscountsQueryVariables
  >(
    {
      query: GetSubscriptionContractDiscounts,
      variables: {
        id,
        discountCursor,
      },
    },
    client
  );
}

export function mutationShopifySubscriptionContractCreate(
  input: SubscriptionContractCreateInput,
  client: ApolloClient<object>
) {
  return throws(
    apolloShopifyMutation<SubscriptionContractCreateMutation, SubscriptionContractCreateMutationVariables>(
      {
        mutation: SubscriptionContractCreate,
        variables: {
          input,
        },
      },
      client
    )
  );
}

export function mutationShopifySubscriptionContractUpdate(id: string, client: ApolloClient<object>) {
  return throws(
    apolloShopifyMutation<SubscriptionContractUpdateMutation, SubscriptionContractUpdateMutationVariables>(
      {
        mutation: SubscriptionContractUpdate,
        variables: {
          id,
        },
      },
      client
    )
  );
}

export function mutationShopifySubscriptionContractSetNextBillingDate(
  contractId: string,
  date: Date,
  client: ApolloClient<object>
) {
  return throws(
    apolloShopifyMutation<
      SubscriptionContractSetNextBillingDateMutation,
      SubscriptionContractSetNextBillingDateMutationVariables
    >(
      {
        mutation: SubscriptionContractSetNextBillingDate,
        variables: {
          contractId,
          date,
        },
      },
      client
    )
  );
}

// Calculates shipping options given an address. Must be polled until shippingOptions
// returns either a success or failure (i.e. not null)
export async function queryShopifySubscriptionDraftShippingOptions(
  draftId: string,
  input: GetSubscriptionDraftShippingOptionsQueryVariables["deliveryAddress"],
  client: ApolloClient<object>
) {
  return apolloShopifyQuery<
    GetSubscriptionDraftShippingOptionsQuery,
    GetSubscriptionDraftShippingOptionsQueryVariables
  >(
    {
      fetchPolicy: "no-cache",
      query: GetSubscriptionDraftShippingOptions,
      variables: {
        id: draftId,
        deliveryAddress: input,
      },
    },
    client
  );
}

export function mutationShopifySubscriptionDraftDiscountCodeApply(
  draftId: string,
  redeemCode: string,
  client: ApolloClient<object>
) {
  return throws(
    apolloShopifyMutation<
      SubscriptionDraftDiscountCodeApplyMutation,
      SubscriptionDraftDiscountCodeApplyMutationVariables
    >(
      {
        mutation: SubscriptionDraftDiscountCodeApply,
        variables: {
          draftId,
          redeemCode,
        },
      },
      client
    )
  );
}

export function mutationShopifySubscriptionDraftDiscountAdd(
  draftId: string,
  input: SubscriptionManualDiscountInput,
  client: ApolloClient<object>
) {
  return throws(
    apolloShopifyMutation<SubscriptionDraftDiscountAddMutation, SubscriptionDraftDiscountAddMutationVariables>(
      {
        mutation: SubscriptionDraftDiscountAdd,
        variables: {
          draftId,
          input,
        },
      },
      client
    )
  );
}

export function mutationShopifySubscriptionDraftDiscountRemove(
  draftId: string,
  discountId: string,
  client: ApolloClient<object>
) {
  return throws(
    apolloShopifyMutation<
      SubscriptionDraftDiscountRemoveMutation,
      SubscriptionDraftDiscountRemoveMutationVariables
    >(
      {
        mutation: SubscriptionDraftDiscountRemove,
        variables: {
          discountId,
          draftId,
        },
      },
      client
    )
  );
}

export function mutationShopifySubscriptionDraftDiscountUpdate(
  draftId: string,
  discountId: string,
  input: SubscriptionManualDiscountInput,
  client: ApolloClient<object>
) {
  return throws(
    apolloShopifyMutation<
      SubscriptionDraftDiscountUpdateMutation,
      SubscriptionDraftDiscountUpdateMutationVariables
    >(
      {
        mutation: SubscriptionDraftDiscountUpdate,
        variables: {
          discountId,
          draftId,
          input,
        },
      },
      client
    )
  );
}

export function mutationShopifySubscriptionDraftFreeShippingDiscountAdd(
  draftId: string,
  input: SubscriptionFreeShippingDiscountInput,
  client: ApolloClient<object>
) {
  return throws(
    apolloShopifyMutation<
      SubscriptionDraftFreeShippingDiscountAddMutation,
      SubscriptionDraftFreeShippingDiscountAddMutationVariables
    >(
      {
        mutation: SubscriptionDraftFreeShippingDiscountAdd,
        variables: {
          draftId,
          input,
        },
      },
      client
    )
  );
}

export function mutationShopifySubscriptionDraftFreeShippingDiscountUpdate(
  draftId: string,
  discountId: string,
  input: SubscriptionFreeShippingDiscountInput,
  client: ApolloClient<object>
) {
  return throws(
    apolloShopifyMutation<
      SubscriptionDraftFreeShippingDiscountUpdateMutation,
      SubscriptionDraftFreeShippingDiscountUpdateMutationVariables
    >(
      {
        mutation: SubscriptionDraftFreeShippingDiscountUpdate,
        variables: {
          discountId,
          draftId,
          input,
        },
      },
      client
    )
  );
}

export async function getSubscriptionContractsAndLinesForCustomer(
  customerId: string,
  client: ApolloClient<NormalizedCacheObject>
) {
  const subscriptions: {
    subscriptionContract: DeepExtractTypeSkipArrays<
      GetSubscriptionContractsForCustomerQuery,
      ["customer", "subscriptionContracts", "edges", "node"]
    >;
    lineItems: DeepExtractTypeSkipArrays<
      GetSubscriptionLineItemsQuery,
      ["subscriptionContract", "lines", "edges", "node"]
    >[];
  }[] = [];
  await paginatedRequestStreamShopifyGraphQL(
    cursor => queryShopifySubscriptionContractsForCustomer(customerId, client, cursor, 5),
    res => res.data.customer!.subscriptionContracts,
    async res => {
      const nodes = res.data.customer?.subscriptionContracts.edges.map(({ node }) => node) || [];
      const subscriptionsWithLineItemPromises = nodes.map(async node => {
        const lineItemsRes = await queryShopifySubscriptionContractLineItems(node.id, node.lineCount, client);

        if (lineItemsRes.type === "error") {
          throw new Error(`Couldn't get line items for shopify subscription contract ${node.id}`);
        }

        const items = lineItemsRes.body.data.subscriptionContract?.lines.edges.map(({ node }) => node) || [];

        subscriptions.push({
          subscriptionContract: node,
          lineItems: items,
        });
      });

      await Promise.all(subscriptionsWithLineItemPromises);
    }
  );

  return subscriptions;
}

export async function getShopifySubscriptionContract(
  contractId: string,
  client: ApolloClient<NormalizedCacheObject>
) {
  const res = await queryShopifySubscriptionContract(contractId, client);
  return parseShopifyGraphQLResponse(res, "subscriptionContract");
}

export async function getShopifySubscriptionContractLineCount(
  contractId: string,
  client: ApolloClient<NormalizedCacheObject>
) {
  const res = await queryShopifySubscriptionContractLineCount(contractId, client);
  return parseShopifyGraphQLResponse(res, "subscriptionContract");
}

export async function createShopifySubscriptionContract(
  input: SubscriptionContractCreateInput,
  client: ApolloClient<NormalizedCacheObject>
) {
  const res = await mutationShopifySubscriptionContractCreate(input, client);
  return parseShopifyGraphQLResponse(res, "subscriptionContractCreate");
}

export async function createShopifySubscriptionDraftFromContract(
  contractId: string,
  client: ApolloClient<NormalizedCacheObject>
) {
  const draftRes = await mutationShopifySubscriptionContractUpdate(contractId, client);

  return parseShopifyGraphQLResponse(draftRes, "subscriptionContractUpdate");
}

export async function updateShopifySubscriptionContract(
  contractId: string,
  client: ApolloClient<NormalizedCacheObject>
) {
  const res = await mutationShopifySubscriptionContractUpdate(contractId, client);

  return parseShopifyGraphQLResponse(res, "subscriptionContractUpdate");
}

export async function setShopifyContractNextBillingDate(
  contractId: string,
  date: Date,
  client: ApolloClient<NormalizedCacheObject>
) {
  const res = await mutationShopifySubscriptionContractSetNextBillingDate(contractId, date, client);

  return parseShopifyGraphQLResponse(res, "subscriptionContractSetNextBillingDate");
}
